import { useEffect, useState } from 'react';

export default function ScreenSize() {
  const [size, setSize] = useState('');

  useEffect(() => {
    window.addEventListener('resize', () => {
      setSize(`${window.innerWidth}px`);
    });
  }, []);

  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.get('ss')) {
    return (
      <div className="sticky top-0 left-0 z-10 w-full h-7 bg-red-500 sm:bg-blue-500 md:bg-green-500 lg:bg-yellow-500 xl:bg-purple-500 2xl:bg-orange-500 text-center text-black overflow-hidden">
        {size} Red = Mobile, Blue = Tablet, Green = Small Laptop, Yellow = Large Laptop, Purple = Desktop, Orange = Large Desktop
      </div>
    );
  }
  return null;

}
