import { getValueByKey } from './utilities';

// import NavBar from './navBar';

export default function Header({ data }) {

  if (data) {
    return (
      <div id="header" className="sticky top-0 w-full z-[2]">
        <div className="h-0 pt-[33%] min-[1080px]:pt-64 mx-auto max-w-[1080px]" style={{ background: `url(${getValueByKey('header_image', data)}) no-repeat top center / contain` }}></div>
      </div>
    );
  }
  return null;
}
