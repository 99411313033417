import { useState, useEffect } from 'react';

import { parsePairedData, loadingMessage, dataFileName } from './utilities';

function Content() {
  const [formData, setFormData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fadeIn, setFadeIn] = useState('');

  useEffect(() => {
    fetch(`/${dataFileName}`).then(response => {
      response.text().then(pairedData => {
        setFormData(parsePairedData(pairedData));
        setIsLoading(false);
        setTimeout(() => {
          setFadeIn('is-visible');
        }, 350);
      });
    });
  }, [setIsLoading]);

  if (isLoading) {
    return (
      <div className="main-container container mx-auto mt-8 p-2">
        {loadingMessage}
      </div>
    );
  }

  const handleFieldChange = event => {
    const { currentTarget } = event;
    const { name, value } = currentTarget;
    const nextData = [...formData];
    const current = nextData.find(a => a.key === name);
    current.value = value;
    setFormData(nextData);
  };

  return (
    <>
      <div className={`main-container container mx-auto mt-8 p-2 fade-in ${fadeIn}`}>
        <form className="w-full mx-auto">
          <div className="join join-vertical w-full">
            {formData.map(item => {
              return (
                <div className="collapse collapse-arrow join-item border border-base-300" key={item.key}>
                  <input type="radio" name="my-accordion-4" defaultChecked />
                  <div className="collapse-title text-xl font-medium">
                    {item.key}
                  </div>
                  <div className="collapse-content">
                    <div className="w-full">
                      <input name={item.key} type="text" placeholder={item.key} value={item.value} onChange={handleFieldChange} className="w-full input input-bordered bg-black" />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </form>
      </div>
    </>
  );
}

export default Content;
