import { useState, useEffect } from 'react';

import { loadingMessage, parsePairedData, dataFileName } from './utilities';

import Header from './header';
// import Section from './section';
// import Footer from './footer';

const baseClasses = 'main-container container mx-auto';

export default function Main() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [fadeIn, setFadeIn] = useState('');

  useEffect(() => {
    fetch(`/${dataFileName}`).then(response => {
      response.text().then(pairedData => {
        setData(parsePairedData(pairedData));
        setIsLoading(false);
        setTimeout(() => {
          setFadeIn('is-visible');
        }, 350);
      });
    });
  }, [setIsLoading]);

  if (isLoading) {
    return (
      <div className={baseClasses}>
        {loadingMessage}
      </div>
    );
  }

  if (!data) {
    return (
      <div className={`${baseClasses} fade-in ${fadeIn}`}>
        Problem loading data.
      </div>
    );
  }

  return (
    <>
      <Header data={data} />
      <div className={`${baseClasses} fade-in ${fadeIn} prose-xl text-center mt-10`}>
        Under Construction
      </div>
    </>
    );
};
