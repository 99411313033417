import { useEffect } from 'react';

function Login({ login }) {

  useEffect(() => {
    login(() => {
      window.location.href = '/admin';
    });
  }, [login]);

  return (
    <div></div>
  );
}

export default Login;
