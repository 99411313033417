const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const serializeResponse = (_key, value) => typeof value === 'bigint' ? value.toString() : value;

const currentPage = () => window.location.pathname.slice(1);

const isAdminPage = () => window.location.pathname.indexOf('admin') > -1;

const formLabel = 'block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2';

const inputField = 'appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white';

const stringToObject = value => {
  const params = {};
  const pairs = value.split('&');
  let i = 0;
  const length = pairs.length;
  for (i; i < length; i++) {
    const pair = pairs[i].split('=');
    const key = decodeURIComponent(pair[0]);
    const value = decodeURIComponent(pair[1] || '');
    if (key.length) {
      params[key] = value;
    }
  }
  return params;
};

const loadingMessage = 'Loading...';

const parsePairedData = pairedData => {
  const data = [];
  pairedData.split('\n').forEach(pair => {
    const kvSplit = pair.split(/=(.*)/s);
    if (kvSplit[0] !== '') {
      data.push({ key: kvSplit[0], value: kvSplit[1] });
    }
  });
  return data;
};

const dataFileName = 'data/f9ee3f9ae3d82fc2964d7c438195b3d3';

const getValueByKey = (key, data) => {
  let value = '';
  data.forEach(item => {
    if (item.key === key) {
      value = item.value;
    }
  });
  return value;
};

const fixTailwindMenuNotDeFocusing = () => {
  const elem = document.activeElement;
  if (elem) {
    elem.blur();
  }
};

export {
  currentPage,
  isAdminPage,
  serializeResponse,
  formLabel,
  inputField,
  stringToObject,
  loadingMessage,
  parsePairedData,
  dataFileName,
  getValueByKey,
  capitalizeFirstLetter,
  fixTailwindMenuNotDeFocusing
};
