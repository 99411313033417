import { createRoot } from 'react-dom/client';
import netlifyIdentity from 'netlify-identity-widget';

import './index.css';
import App from './app';

netlifyIdentity.init();

netlifyIdentity.on('close', () => {
  window.location.href = '/admin';
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
