
export default function AdminHeader({ logout }) {

  const handleLogout = () => {
    logout(() => {
      window.location.href = '/';
    });
  };

  const navLinkStyle = 'current:!bg-white current:!text-black active:!bg-black active:!text-white rounded-md';

  return (
    <>
      <div id="desktop-nav" className="flex relative z-20 min-h-14">
        <div className="bg-neutral-950 text-white flex justify-center w-full fixed top-0 left-0">
          <ul className="menu sm:menu-horizontal pt-3 pb-2">
            <li>
              <a href="/admin/content" className={`${navLinkStyle} mr-4`}>Content</a>
            </li>
            <li>
              <button className={`${navLinkStyle} mr-4`} onClick={handleLogout}>Logout</button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
